.date {
  background-image: url("../img/date-bg1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 90vh;
  font-family: "Rubik";
  &::before {
    width: 260px;
    height: 200px;
    position: absolute;
    top: -120px;
    z-index: 2;
    left: 300px;
    content: "";
    background: url("../img/notedown.png") no-repeat;
    background-size: contain;
    font-family: "Rubik";
    @media (min-width: 1441px) and (max-width: 5000px) {
      left: 570px;
    }
  }
  @media (max-width: 768px) {
    &::before {
      left: 50% !important;
      transform: translate(-50%, 0) !important;
    }
    margin-top: 150px !important;
    height: 1212px;
  }
  &-credit {
    position: absolute;
    right: 0;
    bottom: 0;
    color: $color-white;
    @media (max-width: 768px) {
      font-size: 8px;
    }
  }
  &-content {
    width: 55%;
    position: absolute;
    bottom: 30%;
    left: 50%;
    transform: translate(-50%, 30%);
    text-align: center;
    @media (max-width: 768px) {
      top: 58%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 95%;
    }
    &-title1 {
      margin: 10px 0px;
      font-size: 1.438rem;
      color: $color-white;
      text-transform: uppercase;
      font-weight: bold;
    }
    &-title2 {
      margin: 30px 0px 10px 0px;
      font-size: 3.375rem;
      line-height: 100%;
      color: $color-white;
      text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
    &-separator {
      margin: 30px 0px;
      background: $color-primary;
      border: none;
      border-radius: 40px;
      width: 10%;
      height: 6px;
      margin: 15px 0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 768px) {
        width: 30%;
      }
    }
    &-text1 {
      &.d {
        margin: 10px 0px;
        @include font-size(25px);
        color: $color-white;
        line-height: 110%;
      }
    }
    &-text1 {
      &.mb {
        display: none;
        margin: 10px 0px;
        @include font-size(25px);
        color: $color-white;
        line-height: 110%;
      }
    }
    &-text2 {
      margin: 10px 0px 30px 0px;
      font-size: 1.563rem;
      color: $color-white;
      line-height: 110%;
    }
    @media (max-width: 1280px) {
      &-title1 {
        font-size: 1rem;
        line-height: 1.25rem;
      }
      &-title2 {
        @include font-size(54px);
      }
      &-text1 {
        @include font-size(20px);
      }
      &-text1 {
        &.mb {
          display: block !important;
          @include font-size(20px);
        }
      }
      &-text1 {
        &.d {
          display: none !important;
          @include font-size(20px);
        }
      }
      &-text2 {
        @include font-size(20px);
      }
    }
  }
  &-list {
    color: $color-white;
    width: 200px;
    position: absolute;
    left: -20px;
    transform: translate(0%, -50%);
    top: 50%;
    @media (max-width: 768px) {
      width: 80px;
      left: -15px;
      top: 20px;
      transform: translate(0%, 0px);
    }
    &-item {
      text-align: center;
      padding: 20px;
      margin: 20px 0px;
      border-radius: 21px;
      background: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      @media (max-width: 768px) {
        padding: 5px 5px 5px 15px;
        margin: 10px 0px 20px 0px;
        border-radius: 6px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h4 {
          font-size: 24px !important;
          line-height: 100%;
          margin-bottom: 4px;
        }
        p {
          font-size: 8px !important;
          line-height: 100%;
        }
      }
      h4 {
        opacity: 0.3;
        @include font-size(59px);
        line-height: 90%;
        font-weight: bold;
      }
      p {
        opacity: 0.3;
        @include font-size(17px);
        margin: 0px;
        font-weight: bold;
      }
      &:hover {
        background: $color-primary;
        h4 {
          opacity: 1;
        }
        p {
          opacity: 1;
        }
      }
      &.active {
        background: $color-primary;
        h4 {
          opacity: 1;
        }
        p {
          opacity: 1;
        }
      }
    }
  }
  .pages {
    position: absolute;
    bottom: 30px;
    width: 25%;
    left: 50%;
    display: flex;
    gap: 5px;
    justify-content: space-around;
    transform: translate(-50%, 0%);
    .page {
      width: 20%;
      background-color: $color-primary;
      opacity: 0.5;
      display: block;
      height: 5px;
      border-radius: 40px;
      &.active {
        opacity: 1;
      }
    }
    display: none;
    @media (max-width: 768px) {
      display: flex;
    }
  }
}
