.donate {
  font-family: "Rubik" !important;
  .deck {
    gap: 20px;
  }
  .op-0 {
    opacity: 0;
  }
  .intro {
    text-align: center;
    .title {
      @include font-size(35px);
    }
    .separator {
      background: $color-primary;
      border: none;
      border-radius: 40px;
      width: 10%;
      height: 6px;
      margin: 15px 0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .text {
      @include font-size(18px);
      color: $color-darker;
    }
  }
  @media (max-width: 991px) {
    .card {
      width: 100% !important;
      margin: 0 !important;
    }
    .col-6 {
      margin: 20px 0;
      flex: 0 0 45% !important;
    }
  }
  .card {
    padding-top: 30px;
    background: $color-secondary-fade;
    width: 20%;
    margin: 8px;
    border: none;
    border-radius: 20px;
    text-align: center;
    font-family: "Rubik";
    color: $color-dark;
    min-height: 166px;
    cursor: pointer;
    position: relative;
    &.time {
      &::after {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -45px;
        z-index: 2;
        right: 10px;
        content: "";
        background: url("../img/macaron500.png") no-repeat;
        background-size: contain;
      }
    }
    h3 {
      @include font-size(40px);
    }
    p {
      @include font-size(18px);
      b {
        color: $color-red;
      }
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0px;
      height: 8%;
      opacity: 0;
      width: 10.62%;
    }
    transition: 0.2s ease-in-out 0s;
    &:hover {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.active {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.custom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      label {
        @include font-size(30px);
        width: 15%;
        line-height: 25px;
        text-align: center;
      }
      input {
        border-radius: 21px;
        font-family: "Rubik";
        text-align: center;
        @include font-size(16px);
        border: none;
        padding: 10px;
        width: 75%;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #c39c34;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        @media (max-width: 991px) {
          @include font-size(16px);
          border: none;
          padding: 10px;
          width: 75%;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
          &:-ms-input-placeholder {
            /* IE 10+ */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
          &:-moz-placeholder {
            /* Firefox 18- */
            color: #c39c34;
            text-align: center;
            @include font-size(14px);
            font-family: "Rubik";
            text-transform: uppercase;
            opacity: 1;
          }
        }
      }
    }
  }
}
