.event {
  font-family: "Rubik";
  h2 {
    color: $color-primary;
    font-size: 1.813rem;
    font-weight: bold;
  }
  hr {
    border: 0.25rem solid $color-primary;
    border-radius: 99999rem;
  }
  .text  {
    @include font-size(20px);
    line-height: 125%;
    color: $color-darker;
    position: relative;
  }
  .f-letter {
    @include font-size(38px);
  }
  &-container {
    background-color: $color-gray;

    border-radius: 30px;
    color: $color-primary;
    font-weight: medium;
    text-align: center;
    height: 100%;
    padding: 16% 0px;
    @media (max-width: 768px) {
    }
  }
  #colombe {
    position: relative;
    &::after {
      width: 274.33px;
      height: 192.54px;
      position: absolute;
      bottom: -25%;
      z-index: -1;
      left: -50%;
      content: "";
      background: url("../img/colombe.svg") no-repeat;
      background-size: cover;
    }
  }
  &-item {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 22%;
    &-number {
      width: 20%;
      @include font-size(85px);
      line-height: 60px;
      color: $color-primary;
      margin: 0;
      @media (max-width: 768px) {
        font-size: 80px;
        width: 20%;
      }
    }
    &-img {
      width: 20%;
      @media (max-width: 768px) {
        width: 25%;
      }
    }
    &-title {
      @media (max-width: 768px) {
        width: 45%;
      }
      width: 40%;
      @include font-size(25px);
      line-height: 20px;
      margin: 0;
      text-align: left;
    }
  }
  &-separator {
    @media (max-width: 768px) {
      // margin: 40px 0px;
      margin-left: auto;
      margin-right: auto;
    }
    background: $color-dark;
    border: none;
    width: 80%;
    height: 2px;
    margin: 6% 0px;
    display: block;
    opacity: 0.2;
    margin-left: auto;
    margin-right: auto;
  }
}
