.button {
  background-color: $color-donate;
  border-radius: 28px;
  color: $color-white;
  font-family: Rubik;
  font-weight: 600;
  padding: 11px 32px;
  height: 75%;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0px 8px 11px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 11px 2px rgba(0, 0, 0, 0.15);
  transition: 0.2s ease-in-out 0s;
  img {
    padding-right: 5px;
  }
  &:hover {
    text-decoration: none;
    color: $color-white;
    cursor: pointer;
    transform: scale(1.1);
  }
  @media (max-width: 992px) {
    font-size: 9px;
    padding: 10px 20px;
    height: 21.5%;
    &.no-mb {
      font-size: 16px;
      padding: 11px 32px;
      height: 75%;
    }
  }
  &-img {
    background-color: $color-donate;
    color: $color-donate-text;
    position: absolute;
    font-size: 25px;
    z-index: 5;
    bottom: 0;
    background-color: #a70016;
    color: $color-white;
    text-decoration: none;
  }
  &-cta {
    width: 75%;
    max-width: 228px;
    background-color: $color-donate;
    border-radius: 28px;
    color: $color-white;
    font-family: Rubik;
    font-weight: 600;
    padding: 18px 32px;
    height: 50%;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0px 8px 11px 2px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 8px 11px 2px rgba(0, 0, 0, 0.15);
    transition: 0.2s ease-in-out 0s;
    &:hover {
      text-decoration: none;
      color: $color-white;
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}
